import { getFormattedDateTime } from '@helpers'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { MarkdownHandler } from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'
import useNavHeight from '@hooks/use-nav-height'

const useStyles = makeStyles((theme) => ({
  webinarSeriesEventRoot: {
    borderTop: `1px solid ${theme.palette.border.gray}`,
    marginTop: '32px',
    paddingTop: '32px',
  },
  anchor: ({ navHeight }) => ({
    display: 'block',
    position: 'relative',
    top: `-${navHeight}px`,
    visibility: 'hidden',
  }),
  webinarSeriesEventTitle: {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightRegular,
    margin: '0 0 4px 0',
    [theme.breakpoints.up('lg')]: {
      fontSize: '26px',
    },
  },
  webinarSeriesEventStartDate: {
    color: theme.palette.text.tertiary,
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '16px',
  },
  webinarSeriesEventDescription: {
    color: theme.palette.text.primary,
    marginBottom: '24px',
    '& p': {
      fontSize: '18px',
    },
    '& h5': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightMedium,
      margin: '24px 0 12px 0',
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
      },
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      margin: '0',
      paddingLeft: '32px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      '& p': {
        fontSize: '20px',
      },
      '& ul': {
        fontSize: '20px',
      },
    },
  },
  webinarSeriesEventSpeakersTitle: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    margin: '0 0 16px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },
  webinarSeriesEventSpeakers: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}))

const WebinarSeriesEvent = ({ blok }) => {
  const { description, speakers, startDate, title, eventNo } = blok
  const navHeight = useNavHeight()
  const classes = useStyles({ navHeight })

  return (
    <SbEditable content={blok}>
      <div className={classes.webinarSeriesEventRoot}>
        <div className={classes.anchor} id={`event-${eventNo}`} />
        {!!title && (
          <h3 className={classes.webinarSeriesEventTitle}>{title}</h3>
        )}
        {!!startDate && (
          <div className={classes.webinarSeriesEventStartDate}>
            {getFormattedDateTime(startDate, 'MMMM D YYYY, h:mma z')}
          </div>
        )}
        {!!description && (
          <div className={classes.webinarSeriesEventDescription}>
            <MarkdownHandler>{description}</MarkdownHandler>
          </div>
        )}
        {speakers?.length > 0 && (
          <>
            <h5 className={classes.webinarSeriesEventSpeakersTitle}>
              Speakers
            </h5>
            <div className={classes.webinarSeriesEventSpeakers}>
              {renderBloks(speakers, { variant: 'webinarSeries' })}
            </div>
          </>
        )}
      </div>
    </SbEditable>
  )
}

export default WebinarSeriesEvent
